<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="(apiLoaded || errorText) && ($user.role==='ROLE_VNII' || $user.role==='ROLE_ADMIN')">
        <div v-if="apiLoaded" class="wrap">
          <a @click="$router.go(-1)" class="back-link">
            <v-icon color="#0033A0">mdi-chevron-left</v-icon>
            Назад
          </a>
          <h1 v-if="!fromSettingPage">Комплексная модернизация по срезам
            <div class="download-menu">
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"/>
                    </svg>
                  </div>
                </template>
                <StandardExportDialog @close="dialog=false; dialogKey++" :key="dialogKey"/>
              </v-dialog>
            </div>
<!--            <v-dialog-->
<!--                v-model="dialog"-->
<!--                persistent-->
<!--                max-width="600px"-->
<!--            >-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn outlined v-bind="attrs" v-on="on" style="float: right;">Экспорт</v-btn>-->
<!--              </template>-->
<!--              <StandardExportDialog @close="dialog=false; dialogKey++" :key="dialogKey"/>-->
<!--            </v-dialog>-->
            <div>
              <h4>Тип среза:</h4>
                <v-radio-group v-model="monitoringFormat" row @change="initializeData()">
                  <v-radio label="По регионам или ЦЗН" value="byRegionsOrCZN"></v-radio>
                  <v-radio label="По мониторингам" value="byReports"></v-radio>
                </v-radio-group>
            </div>
            <template v-if="monitoringFormat === 'byRegionsOrCZN'">
              <div>
                <h4>Уровень мониторинга:</h4>
                  <v-radio-group v-model="monitoringLevel" row @change="initializeData()">
                    <v-radio label="Регионы" value="region"></v-radio>
                    <v-radio label="ЦЗН" value="czn"></v-radio>
                  </v-radio-group>
              </div>
              <department-info v-if="monitoringLevel === 'czn'"
                               @minput="department = $event; initializeData()"
              ></department-info>
              <v-autocomplete
                  v-else
                  style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                  dense
                  label="Регион"
                  :items="regionList"
                  item-text="name"
                  return-object
                  v-model="userRegion"
                  @input="initializeData()"
              ></v-autocomplete>
            </template>
            <template v-if="monitoringFormat === 'byReports'">
              <v-autocomplete
                  style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                  dense
                  label="Мониторинг"
                  :items="allMonitoringReportList"
                  item-text="name"
                  return-object
                  v-model="monitoringReport"
                  @input="checkMonitoringReportType(); initializeData()"
              ></v-autocomplete>
            </template>
          </h1>
          <h1 v-else>Комплексная модернизация по срезам
            <div>
              <h4>
                {{ config.title }} [id{{ config.id }}]
              </h4>
            </div>
          </h1>

          <div class="list">
            <template v-if="monitoringFormat === 'byRegionsOrCZN'">
              <table>
                <thead>
                  <tr>
                    <th>Отчёты</th>
                    <th>Дата создания</th>
                    <th>Дата отправки</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="report in reportList" :key="report.id">
                    <td>
                      <router-link :to="'/standard/report/' + report.id">{{ report.monitoring.name }}</router-link>
                    </td>
                    <td>{{ report.createDate }}</td>
                    <td v-if="report.sendDate">{{ report.sendDate }}</td>
                    <td v-else>—</td>
                    <td>{{ reportStatuses[report.status] }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="reportList.length === 0" style="height: 48px; margin-top: 20px">
                Не найдены записи по комплексной модернизации
              </div>
            </template>
            <template v-else-if="monitoringFormat === 'byReports'">
              <table>
                <thead>
                <tr>
                  <th v-if="monitoringReportType === 'czn'">ЦЗН</th>
                  <th v-else-if="monitoringReportType === 'region'">Регионы</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
                </thead>
                <tbody v-if="monitoringReportType === 'czn'">
                <tr v-for="reportCZN in reportCZNList" :key="reportCZN.id">
                  <td>
                    <router-link :to="'/standard/report/' + reportCZN.id">{{ reportCZN.czn.name }}</router-link>
                  </td>
                  <td>{{ reportCZN.createDate }}</td>
                  <td v-if="reportCZN.sendDate">{{ reportCZN.sendDate }}</td>
                  <td v-else>—</td>
                  <td>{{ reportStatuses[reportCZN.status] }}</td>
                </tr>
                </tbody>
                <tbody v-else-if="monitoringReportType === 'region'">
                <tr v-for="reportRegion in reportRegionList" :key="reportRegion.id">
                  <td>
                    <router-link :to="'/standard/report/' + reportRegion.id">{{ reportRegion.region.name }}</router-link>
                  </td>
                  <td>{{ reportRegion.createDate }}</td>
                  <td v-if="reportRegion.sendDate">{{ reportRegion.sendDate }}</td>
                  <td v-else>—</td>
                  <td>{{ reportStatuses[reportRegion.status] }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="(monitoringReportType === 'czn' && reportCZNList.length === 0) ||
                         (monitoringReportType === 'region' && reportRegionList.length === 0)" style="height: 48px; margin-top: 20px">
                Не найдены записи по комплексной модернизации
              </div>
            </template>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
import StandardExportDialog from "@/components/dialogs/StandardExportDialog";
import departmentInfo from "@/components/elements/DepartmentInfo";
import api from "@/modules/api";

export default {
  name: 'StandardVniiComponent',
  components: {
    departmentInfo,
    StandardExportDialog,
  },

  data: () => ({
    dialog: false,
    dialogKey: 0,
    monitoringFormat: "byRegionsOrCZN",
    monitoringLevel: "region",
    allMonitoringReportList: [],
    monitoringReport: {id: null},
    reportList: [],
    department: null,
    regionList: [],
    userRegion: {},
    monitoringReportType: null,
    reportCZNList: [],
    reportRegionList: [],
    reportStatuses: {
      DRAFT: "Черновик",
      SENT: "Отправлено"
    },
    fromSettingPage: false,
    configId: null,
    config: null,
    apiLoaded: false,
    errorText: null,
  }),

  methods: {
    async initializeData(){
      await this.updateData();
    },

    async updateData() {
      let req,
          obj = {};
      if (this.monitoringFormat === "byRegionsOrCZN") {
        if (this.monitoringLevel === "czn") {
          obj.type = "formdata_czn";
          obj.departmentName = this.department;
        } else if (this.monitoringLevel === "region") {
          obj.type = "formdata_region";
          obj.regionCode = this.userRegion.code;
        }
      } else if (this.monitoringFormat === "byReports") {
        obj.type = "formdata_monitoring";
        obj.monitoringId = this.monitoringReport.id;
      }
      // console.log('obj', obj)
      req = await api.postJson("/monitoring/typical/formData/getList", obj);
      if (req.ok) {
        if (this.monitoringFormat === "byRegionsOrCZN") {
          this.reportList = req.payload;
          // console.log('this.configs', this.configs)
          this.reportList.sort((a, b) => (a.createdDate > b.createdDate) ? 1 : -1);
          // console.log('this.reportList', this.reportList)
        } else if (this.monitoringFormat === "byReports") {
          if (this.monitoringReportType === "czn") {
            this.reportCZNList = req.payload;
            this.reportCZNList.sort((a, b) => (a.czn.name > b.czn.name) ? 1 : -1);
            // console.log('this.reportCZNList', this.reportCZNList)
          } else if (this.monitoringReportType === "region") {
            this.reportRegionList = req.payload;
            this.reportRegionList.sort((a, b) => (a.region.name > b.region.name) ? 1 : -1);
            // console.log('this.reportRegionList', this.reportRegionList)
          }
        }
        this.apiLoaded = true;
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async getMonitoringReportList() {
      let req = await api.get("/monitoring/typical/getList");
      if (req.ok) {
        this.allMonitoringReportList = req.payload;
        // Сортировка по id
        this.allMonitoringReportList.sort((a, b) => (a.id < b.id ? 1 : -1));
        // console.log('this.allMonitoringReportList', this.allMonitoringReportList)
        this.monitoringReport = this.allMonitoringReportList[0];
        this.checkMonitoringReportType();
      } else {
        console.log('get error');
      }
    },

    checkMonitoringReportType() {
      if ("czns" in this.monitoringReport) {
        this.monitoringReportType = "czn";
      } else if ("regions" in this.monitoringReport) {
        this.monitoringReportType = "region";
      }
    },

    async getRegionList() {
      let req = await api.get("/getRegionsList");
      if (req.ok) {
        this.regionList = req.payload;
        // Сортировка по алфавиту
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
        //console.log('region', this.userRegion);
      } else {
        console.log('get regionList error');
      }
    },

    async loadConfig() {
      let req = await this.$getApi("/monitoring/typical/config/getConfig", {id: this.configId});
      if (req.ok) {
        this.config = req.payload;
        this.config.configParams = JSON.parse(this.config.configJson);
        console.log('this.config', this.config)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },

  async beforeMount() {
    this.configId = this.$route.params.configId;
    if (this.configId != null) { //при переходе из реестра конфигов
      this.fromSettingPage = true;
      this.monitoringFormat = "byReports";
      await this.loadConfig();
      this.monitoringReportType = this.config.configParams.type
      this.monitoringReport.id = this.config.monitoring.id;
    } else {
      await this.getRegionList();
      this.userRegion = this.regionList[0];
      await this.getMonitoringReportList();
    }
    await this.initializeData();
    this.apiLoaded = true;
  }
}
</script>


<style scoped lang="scss">
  .wrap > h1 > div:not(.download-menu) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #1C1C1F;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    .v-input--selection-controls .v-radio > .v-label {
      font-size: 15px;
    }
    > div {
      width: 450px;
      &:first-child {
        margin-right: 77px;
      }
    }
  }

  .list {
    table {
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #E8E8E9;
        &:last-child {
          width: 100px;
          text-align: center;
        }
      }
      td {
        &:last-child {
          width: 100px;
          text-align: center;
        }
      }
    }
  }

  .download-menu {
    top: 0;
    margin-top: 0;
  }

</style>

<style>
.v-input--radio-group__input {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
</style>

<style lang="scss">
@import "../styles/main.scss";
</style>


